import Swiper from 'swiper';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

/**
 * Slider
 * @param {string|HTMLElement} element - A DOM node element or a selector
 */
export default (options = {}, element = '.swiper') => ({
  swiper: null,
  realIndex: 0,

	init() {
    const el = typeof element === 'string' ? this.$el.querySelector(element) : element;
    const defaultOptions = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      modules: [EffectFade, Navigation, Pagination],
    };

    this.swiper = new Swiper(el, {
      ...defaultOptions,
      ...options,
    });

    const setRealIndex = () => {
      this.realIndex = this.swiper.realIndex;
    }

    this.swiper.on('realIndexChange', setRealIndex);
  }
})
