// @ts-check

/**
 * @typedef dataEntry
 * @property {number} id
 * @property {string} title
 * @property {?string} uri
 */

/**
 * @typedef filter
 * @property {Array<dataEntry>} data
 * @property {string} dataKey
 * @property {string} elementId
 * @property {string} filterKey
 * @property {string} urlKey
 */

/**
 * @typedef config
 * @property {Array<filter>} filters
 */

import { split } from "lodash-es";
/**
 * Returns an object containing properties related to product filtering and pagination.
 * @returns {object} The products object.
 */
export default function itineraries(entries = "", config = {}) {
  return {
    /** @type {config} Configuration */
    config: { filters: [] },

    get filters() {
      return this.config.filters;
    },

    get selectedCount() {
      return this.filters.map((filter) => filter.selected).flat().length;
    },

    /** @type {object[]} Dataset to filter */
    entries: [],

    /** @type {number} The current page number. */
    page: 1, // Current page

    /** @type {number} The number of entries to display per page. */
    perPage: 12, // Number of entries per page

    /** @type {string} The current sort order. */
    sortBy: "asc",

    /** @type {boolean} Indicates whether data is currently being loaded. */
    loading: false, // true when loading data

    /**
     * Init
     */
    init() {
      try {
        this.validateConfig(config);
      } catch (error) {
        console.error(error);
        return;
      }

      this.config = config;

      this.filters.forEach((filter) => {
        filter.data = JSON.parse(filter.data);
        filter.el = this.$el.querySelector(`#${filter.elementId}`) ?? null;
      });

      this.processRequest();
      this.preventSelectionWhileLoading();

      this.entries = JSON.parse(entries);

      this.$watch("sortBy", () => {
        this.handleSortChange();
      });

      this.$watch("page", () => {
        this.replaceHistoryState();
      });
    },

    /**
     * validateConfig
     */
    validateConfig(config) {
      if (!config) {
        throw new Error("Config object is required.");
      }

      if (config instanceof Object === false) {
        throw new Error("Config must be an object.");
      }

      if (!config.filters) {
        throw new Error("config.filters is required.");
      }

      if (config.filters instanceof Array === false) {
        throw new Error("config.filters must be an array.");
      }
    },

    /**
     * getFilter
     * @param {string} filterKey
     */
    getFilter(filterKey) {
      return this.filters.find((filter) => filter.filterKey === filterKey);
    },

    /**
     * getFilterDataEntryByDataEntryId
     * @description Returns the data entry object for a data entry id
     */
    getFilterDataEntryByDataEntryId(id) {
      return this.filters
        .map((filter) => filter.data)
        .flat()
        .find((data) => data.id === id);
    },

    /**
     * processRequest
     * @description Processes the URL request
     */
    processRequest() {
      const url = new URL(window.location.toString());

      const parse = (value) => {
        return isNaN(value) ? value : +value;
      };

      this.filters.forEach((filter) => {
        let uKey = filter.urlKey;
        let fKey = filter.filterKey;
        this.setFilterSelected(
          fKey,
          url.searchParams.get(uKey)
            ? split(url.searchParams.get(uKey), ",").map(v => v.trim()).map(parse)
            : []
        );
      });

      const pageParam = url.searchParams.get("page");
      this.page = pageParam ? +pageParam : 1;
    },

    /**
     * setFilterSelected
     * @description Sets the selected property of a filter object
     */
    setFilterSelected(filterKey, value) {
      const filter = this.getFilter(filterKey);

      if (filter) {
        filter.selected = value;
      }
    },

    /**
     * unsetFilterSelection
     */
    unsetFilterSelection(filterKey, value) {
      const filter = this.getFilter(filterKey);

      if (filter) {
        // Remove the value from the selected array
        filter.selected.splice(filter.selected.indexOf(value), 1);

        // Push the change to the history state
        this.replaceHistoryState();
      }
    },

    /**
     * preventSelectionWhileLoading
     */
    preventSelectionWhileLoading() {
      this.$watch("loading", (loading) => {
        if (loading) {
          this.filters.forEach(({ el }) => {
            if (el) {
              el.disabled = true;
            }
          });
        } else {
          this.filters.forEach(({ el }) => {
            // Keep track of the disabled state using a data attrib
            // So that we don't re-enable filters that were disabled by null results
            if (el && !el.dataset.disabled) {
              el.disabled = false;
            }
          });
        }
      });
    },

    /**
     * filteredEntries
     * @param {string} ignore
     * @returns {object[]}
     */
    filteredEntries(ignore = null) {
      return this.filters.reduce((filteredData, filter) => {
        if (ignore === filter.dataKey) {
          return filteredData;
        }

        return filteredData.filter((entry) => {
          if (filter.selected.length === 0) {
            return true;
          }

          return filter.selected.some((selectedValue) => {
            return entry[filter.dataKey][selectedValue];
          });
        });
      }, this.entries);
    },

    /**
     * handleFilterClick
     */
    handleFilterClick(filterKey, value) {
      const filter = this.getFilter(filterKey);

      if (filter) {
        if (filter.multipleSelect) {
          if (filter.selected.includes(value)) {
            filter.selected.splice(filter.selected.indexOf(value), 1);
          } else {
            filter.selected.push(value);
          }
        } else {
          filter.selected = [value];
        }

        this.loading = true;
        this.handleFilterChange();
      }
    },

    /**
     * handleFilterChange
     */
    handleFilterChange() {
      this.page = 1;
      this.replaceHistoryState();
      this.loading = false;
    },

    /**
     * getFilterCount
     */
    getFilterCount(entryKey, value, ignore = null) {
      return this.filteredEntries(ignore).filter(
        (entry) => entry[entryKey] && entry[entryKey][value]
      ).length;
    },

    /**
     * handleSortChange
     */
    handleSortChange() {
      if (this.sortBy === "asc") {
        this.entries.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
      if (this.sortBy === "desc") {
        this.entries.sort((a, b) => {
          return b.title.localeCompare(a.title);
        });
      }
      this.page = 1;
      this.replaceHistoryState();
      this.loading = false;
    },

    /**
     * isOptionFound
     * @param {string} option
     * @param {number} targetId
     * @returns {boolean}
     */
    isOptionFound(option, targetId, omitCategory = "") {
      for (let entry of this.filteredEntries(omitCategory)) {
        if (Object.keys(entry[option]).includes(targetId.toString())) {
          return true;
        }
      }
      return false;
    },

    /**
     * paginatedEntries
     */
    get paginatedEntries() {
      // Calculate the start and end entries for the current page
      const start = (this.page - 1) * this.perPage;
      const end = start + this.perPage;

      // Return the entries for the current page
      return this.filteredEntries().slice(start, end);
    },

    /**
     * pagination
     */
    get pagination() {
      // Calculate the number of pages
      const pages = this.totalPages;

      // Create an array of pages
      const pagination = Array.from({ length: pages }, (_, i) => i + 1);

      // If there are more than 2 pages, filter out the pages that are not within 2 of the current page
      if (pages > 2) {
        return pagination.filter(
          (page) =>
            page === 1 ||
            page === this.page ||
            page === pages ||
            (page >= this.page - 2 && page <= this.page + 2)
        );
      } else {
        // Otherwise, return all the pages
        return pagination;
      }
    },

    /**
     * total pages
     */
    get totalPages() {
      // Calculate the number of pages
      return Math.ceil(this.filteredEntries().length / this.perPage);
    },

    /**
     * @param {number} page
     */
    goToPage(page) {
      // Set the current page
      this.page = page;

      // Scroll to #results
      const results = document.querySelector("#results");
      if (results) {
        results.scrollIntoView({
          behavior: "smooth",
        });
      }
    },

    /**
     * replaceHistoryState
     */
    replaceHistoryState() {
      const params = new URLSearchParams(window.location.search);

      this.filters.forEach((filter) => {
        params.set(filter.urlKey, filter.selected.join(","));
      });

      params.set("page", this.page);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`
      );
    },

    /**
     * Reset a filter
     */
    resetFilter(filterKey) {
      const filter = this.getFilter(filterKey);

      if (filter) {
        filter.selected = [];
        this.loading = true;
        this.handleFilterChange();
      }
    },

    /**
     * Resets all filter values to empty arrays.
     */
    resetFilters() {
      this.filters.forEach((filter) => {
        filter.selected = [];
      });

      this.loading = true;
      this.handleFilterChange();
    },
  };
}
