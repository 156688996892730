/* CSS */
import "@js/parts/css";

/* Non-alpine JS */
//

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
*/
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import collapse from "@alpinejs/collapse";
import persist from "@alpinejs/persist";

Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.plugin(persist)

import filterEntries from "@js/parts/filterEntries";
import gMap from "@js/parts/gMap";
import slider from "@js/parts/slider";

/* Register components */
Alpine.data("filterEntries", filterEntries);
Alpine.data("gMap", gMap);
Alpine.data("slider", slider);

/* Global alpine store */
Alpine.store("global", {
  headerHasOpenMenu: false,
  mobileMenuOpen: false,
  innerWidth: window.innerWidth,
  showSearch: false,
  showGallery: false,

  init() {
    window.addEventListener("resize", () => {
      this.innerWidth = window.innerWidth;
    });
  },
});

Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
